import React, {useEffect, useState} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import {useAppInfo} from "../../../../helpers/hooks/common-hook";
import TextBox from "../../../../components/forms/text-box";
import SettingTip from "../../common/components/setting-tip";

function Page({name, onChangeData, savedSettings, metadata={}}) {
    console.log("metadata", metadata);
    const { appId } = useAppInfo();
    const [formData, setFormData] = useState(savedSettings);

    useEffect(() => {
        formData.stateType = ACTION_PROMPT
        onChangeData(formData);
    }, [formData]);

    const handleChange = event => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        const newFormData = {...formData}

        if (targetName === 'name') {
            newFormData.name = targetValue;
        }

        setFormData(newFormData);
    };

    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="The HTM step allows to transfer conversation to human agent"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

        </div>
    )
}

export default Page

import {Switch} from "@headlessui/react";
import React from "react";

export default function UseChatHistorySwitch({useChatHistory, formData, setFormData}) {

    if (useChatHistory === undefined) {
        useChatHistory = true
    }
    return (
        <div className="pt-4 flex flex-row justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Use Chat History
            </label>
            <Switch
                checked={useChatHistory}
                onChange={val=>setFormData({...formData, useChatHistory: val})}
                className={`${
                    useChatHistory ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Use Chat History</span>
                <span
                    className={`${
                        useChatHistory ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
    )
}

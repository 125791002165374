import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import TextBox from "../../../../components/forms/text-box";
import { useEventBus } from "../../../../helpers/hooks/event-bus";
import ActionButtons from "../../common/components/buttons-settings";
import DisplayTexts from "../../common/components/display-texts";
import SettingTip from "../../common/components/setting-tip";
import SlotSelector from "../../common/components/slot-selector";
import { WORKFLOW_OPTION_SLOT } from "../../common/const/settings-const";
import {ACTION_PROMPT, QUESTION_PROMPT} from "../../common/const/settings-type";
import { StmStateDto } from "../../common/dto/stm-state-dto";
import { Card } from "flowbite-react";
import isEqual from "lodash/isEqual";
import TextArea from "../../../../components/forms/text-area";

function Page({ id: stepId, name, onChangeData, savedSettings, metadata }) {
  const [formData, setFormData] = useState(savedSettings);

  const notifyOnchangeData = data => {
    data.slot = data.slot || WORKFLOW_OPTION_SLOT;
    data.stateType = QUESTION_PROMPT
    onChangeData(data);
  }

  useEffect(() => {
    notifyOnchangeData(formData);
  }, [formData]);

  const handleChange = useCallback(event => {
    const { name, value } = event.target;

    setFormData(prevFormData => {
      // if (prevFormData[name] === value) return prevFormData;
      return {
        ...prevFormData,
        [name]: value
      };
    });
  }, []);

  const onDynamicButtonRowChange = useCallback((rowsData, field) => {
    rowsData = rowsData.map(raw=>raw.inputs)
    const newFormData = {...formData};
    newFormData.data = rowsData
    setFormData(newFormData); // Update local state first
    notifyOnchangeData(newFormData);
  }, [formData])

  const memoizedDisplayTextOptions = useMemo(() => formData.displayTextOptions, [formData.displayTextOptions]);

  return (
    <div className="flex flex-col gap-2">
      <SettingTip text="Buttons offer clickable options, enabling users to select from multiple choices. This component is used to capture intent through a button click and can be configured to set a slot value, input to the agent, open a URL, or navigate to a specific step in the workflow" />
      <div className="p-4 bg-slate-50 dark:bg-slate-800 border rounded-md border-slate-200">
        <TextBox
          label="Name"
          type="text"
          name="name"
          placeholder="State name"
          value={formData.name}
          onChange={handleChange}
        />
        <SlotSelector
          title="Default Variable"
          value={formData.slot}
          autoFillFromEntity={formData.autoFillFromEntity}
          slots={metadata.slots}
          onChange={handleChange}
        />
        <DisplayTexts
          rootClassName="pt-4"
          displayTopBorder={false}
          parameterName={formData.slot}
          displayTextOptionsProps={memoizedDisplayTextOptions}
          responseModifyByAI={formData.responseModifyByAI}
          onChangeData={handleChange}
        />
        <TextArea
            rootClass="pt-4"
            label="Options Title"
            type="text"
            name="optionsTitle"
            placeholder="Options Title"
            value={formData.optionsTitle}
            onChange={handleChange}
        />
      </div>
      <ActionButtons
        stepId={stepId}
        dataField='data'
        configs={savedSettings.data}
        displayTopBorder={false}
        onChange={onDynamicButtonRowChange}
        defaultSlot={formData.slot}
        metadata={metadata}
      />
    </div>
  )
}

export default Page

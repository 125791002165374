import { Auth } from "aws-amplify";
import { useCallback, useState } from 'react';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo/main-color.png';
import glogo from '../../../assets/images/icons/g-logo.png';
import Loader from "../../../components/form-loader/form-loader";
import DropDown from "../../../components/forms/dropdown";
import TextBox from "../../../components/forms/text-box";
import Message from "../../../components/message/message-comp";
import config from "../../../config";

const CHANNELS = [
  "Search Engine",
  "Linkedin",
  "Facebook",
  "Instagram",
  "Reddit",
  "YouTube",
  "Other"
].map(t => ({ label: t, value: t }))

function SignupPage() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [triedSubmit, setTriedSubmit] = useState(false);

  const [auth, setAuth] = useState({
    username: '',
    password: '',
    firstname: '',
    lastname: '',
    organization: '',
    website: '',
    how_here_about_us: '',
    rememberMe: false
  });

  const [error, setError] = useState({
    username: false,
    name: false,
    password: false
  });

  const [formState, setFormState] = useState({
    processing: false,
    success: false,
    failed: false,
    message: ''
  });

  const handleChange = useCallback(event => {
    const targetName = event.target.name;
    auth[targetName] = event.target.value;

    if (triedSubmit) {
      validateForm()
    }

    setAuth(Object.assign({}, auth));
  });

  const validateForm = () => {
    let valid = true;
    if (!auth.username) {
      error.username = true;
      valid = false;
    } else {
      error.username = false;
    }

    if (!auth.password) {
      error.password = true;
      valid = false;
    } else {
      error.password = false;
    }

    if (!auth.firstname) {
      error.firstname = true;
      valid = false;
    } else {
      error.firstname = false;
    }

    setError(Object.assign({}, error))
    return valid;
  }

  const updateFormState = (processing, success, failed, message) => {
    setFormState(Object.assign({}, { processing, success, failed, message }))
  }

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setTriedSubmit(true);
    if (!formState.processing && validateForm()) {
      updateFormState(true, false, false);
      try {
        await Auth.signUp({
          username: auth.username, password: auth.password,
          attributes: {
            'given_name': auth.firstname,
            'family_name': auth.lastname || auth.firstname,
            'name': auth.firstname + " " + auth.lastname,
            // 'custom:full_name': auth.name,
            'custom:organization': auth.organization,
            'custom:website': auth.website,
            'custom:how_here_about_us': auth.how_here_about_us,
          }
        });
        updateFormState(false, true, false);
        navigate(`/signin?sign_up=success&account=${auth.username}`);
      } catch (err) {
        console.error("signup failed cause", err);
        updateFormState(
          false,
          false,
          true,
          err.message.includes("User not invited") ? "We regret to inform you that your account has not been enabled yet. Please do not hesitate to contact our team for further assistance." : err.message);
      }
    }
  });

  const handleGoogleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (!formState.processing) {
      updateFormState(true, false, false);
      try {
        await Auth.federatedSignIn({
          provider: "google",
        });
        updateFormState(false, true, false);
        navigate('/');
      } catch (err) {
        console.error("signin failed cause", err);
        updateFormState(false, false, true, err.message);
      }
    }
  });

  const onGoogleLogin = (event) => {
    event.preventDefault();
    // TODO remove hard code
    window.location.href = 'https://auth.botcircuits.com/login?client_id=2eo8l3s0doeragdndaueqmarht&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://platform.botcircuits.com'
  }

  return (
    <>
      <div className="h-screen bg-slate-100 flex justify-center py-12 px-4 sm:px-6 lg:px-8 overflow-y-auto">
        <div className="max-w-md w-full space-y-8">
          <div>
            <Link to="/">
              <img
                className="mx-auto h-16 w-auto"
                src={logo}
                alt="Workflow"
              />
            </Link>
            <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">Welcome to {config.APP_NAME}</h2>
          </div>

          {formState.processing && <Loader />}

          {formState.failed &&
            <div className="flex justify-center items-center">
              <Message text={formState.message ? formState.message : 'Your details are incorrect and the signup was not successful. Please check them, and try again.'} type="error" />
            </div>
          }

          {formState.processing &&
            <div class="flex justify-center items-center">
              <div
                class="animate-spin rounded-full span-btn btn-sm border-t-2 border-b-2 border-primary-color"
              >
              </div>
            </div>
          }

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm pb-8">
            <div className="bg-white border border-slate-200 py-6 px-6 shadow-lg rounded-lg">
              <form className="space-y-4" onSubmit={handleSubmit} method="POST">
                <TextBox
                  type="email"
                  name="username"
                  id="username"
                  label="Email"
                  value={auth.username}
                  placeholder="Enter your email address"
                  onChange={handleChange}
                  error={error.username}
                />

                <TextBox
                  type="text"
                  name="firstname"
                  id="firstname"
                  label="Name"
                  value={auth.firstname}
                  placeholder="Enter your name"
                  onChange={handleChange}
                  error={error.firstname}
                />
                {/*<TextBox*/}
                {/*  type="text"*/}
                {/*  name="lastname"*/}
                {/*  id="lastname"*/}
                {/*  label="Last name"*/}
                {/*  value={auth.lastname}*/}
                {/*  placeholder="Enter your last name"*/}
                {/*  onChange={handleChange}*/}
                {/*  error={error.lastname}*/}
                {/*/>*/}
                <div className="relative">
                  <TextBox
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    label="Password"
                    value={auth.password}
                    placeholder="Enter password"
                    onChange={handleChange}
                    error={error.password}
                  />
                  <div className="absolute right-3 bottom-2">
                    {showPassword ? <HiOutlineEye size={20} onClick={() => setShowPassword(false)} /> : <HiOutlineEyeOff size={20} onClick={() => setShowPassword(true)} />}
                  </div>
                </div>

                {/*<TextBox*/}
                {/*  type="text"*/}
                {/*  name="organization"*/}
                {/*  id="organization"*/}
                {/*  label="Company"*/}
                {/*  value={auth.organization}*/}
                {/*  placeholder="Enter your company name"*/}
                {/*  onChange={handleChange}*/}
                {/*  optional={true}*/}
                {/*/>*/}

                {/*<TextBox*/}
                {/*  type="text"*/}
                {/*  name="website"*/}
                {/*  id="website"*/}
                {/*  label="Website"*/}
                {/*  value={auth.website}*/}
                {/*  placeholder="Enter the link to your website"*/}
                {/*  onChange={handleChange}*/}
                {/*  optional={true}*/}
                {/*/>*/}

                <DropDown
                  className=""
                  optionClassName="capitalize"
                  label="How did your here about us"
                  placeHolder="Select channel"
                  name="how_here_about_us"
                  value={auth.how_here_about_us}
                  options={CHANNELS}
                  onChange={handleChange}
                  optional={true}
                />
                <div className="pt-3">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Sign up
                  </button>
                </div>

                <div className="pt-3">
                  <button
                      onClick={onGoogleLogin}
                      className="flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <img
                        src={glogo}
                        alt="Google"
                        className="h-5 w-5 mr-3"
                    />
                    Sign in with Google
                  </button>
                </div>

                <div className="text-sm pt-6">
                  Existing User? Let's{" "}
                  <Link to="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Sign in instead.
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupPage;

/* eslint-disable */
export default {
    Auth: {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
            redirectSignIn: process.env.REACT_APP_COGNITO_AUTH_CALLBACK_URL,
            redirectSignOut: process.env.REACT_APP_COGNITO_AUTH_CALLBACK_URL,
            responseType: "code"
        }
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_RESOURCE_BUCKET,
            region: process.env.REACT_APP_AWS_COGNITO_REGION
        }
    },
};

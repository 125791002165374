import {Switch} from "@headlessui/react";
import React from "react";

export default function UseChatHistorySwitch({addToContext, formData, setFormData}) {
    if (addToContext === undefined) {
        addToContext = true
    }
    return (
        <div className="pt-4 flex flex-row justify-between items-center">
            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Add into context
            </label>
            <Switch
                checked={addToContext}
                onChange={val=>setFormData({...formData, addToContext: val})}
                className={`${
                    addToContext ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
                <span className="sr-only">Add into context</span>
                <span
                    className={`${
                        addToContext ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
    )
}

import React, {useEffect, useState, useCallback} from "react";
import {StmStateDto} from "../../common/dto/stm-state-dto";
import {ACTION_PROMPT} from "../../common/const/settings-type";
import SlotSelector from "../../common/components/slot-selector";
import TextBox from "../../../../components/forms/text-box";
import TextArea from "../../../../components/forms/text-area";
import SettingTip from "../../common/components/setting-tip";
import DropDown from "../../../../components/forms/dropdown";
import DynamicRows from "../../../../components/dynamic-rows";
import SentToUserSwitch from "../../common/components/send-to-user-switch";
import AddToContextSwitch from "../../common/components/add-to-context";
import UseChatHistorySwitch from "../../common/components/use-chat-history";

const prepareRawsData = (data=[]) => {
    return data.map(header=>({inputs: header}))
}

const getDividerCss = (showTopDivider, showBottomDivider) => {
    let dividerCss = 'border-gray-100';

    if (showTopDivider) {
        dividerCss = `${dividerCss} border-t-2 pt-2 mt-4`
    }

    if (showBottomDivider) {
        dividerCss = `${dividerCss} border-b-2 pb-2 mb-4`
    }

    return dividerCss;
}

function AITaskSettings({ name, onChangeData, savedSettings, metadata }) {
    const [formData, setFormData] = useState(savedSettings);

    const notifyOnchangeData = data => {
        data.stateType = ACTION_PROMPT
        onChangeData(data);
    }

    useEffect(() => {
        notifyOnchangeData(formData)
    }, [formData]);

    const handleChange = useCallback(event => {
        const { name, value } = event.target;

        setFormData(prevFormData => {
            // if (prevFormData[name] === value) return prevFormData;
            return {
                ...prevFormData,
                [name]: value
            };
        });
    }, []);

    const onChangeResponseMapper = (rowsData=[]) => {
        rowsData = rowsData.map(raw=>raw.inputs)
        console.log('onChangeResponseMapper', rowsData)
        const newFormData = {...formData};
        newFormData.assignToSlots.executors = rowsData
        notifyOnchangeData(newFormData);
    }

    const renderRow = useCallback((valuesMap, handleInputChange, rawId) => {
        return <div className="w-full flex flex-col gap-4" key={rawId}>
            <TextArea
                rootClass=""
                label="Prompt"
                type="text"
                name="inputPrompt"
                placeholder="recommend me a {dish} that's easy to cook?"
                value={valuesMap.inputPrompt}
                onChange={handleInputChange}
                rows={6}
            />
            <DropDown
                className=""
                placeHolder="Select slot"
                label="Apply to Slot"
                name="slotToAssign"
                value={valuesMap.slotToAssign}
                options={Object.keys(metadata.slots)}
                onChange={handleInputChange}
            />
        </div>
    }, [])
    return (
        <div className="flex flex-col gap-2">

            <SettingTip text="Generate answer and assign to Slot based on a prompt that you provide"/>

            <TextBox
                label="Name"
                type="text"
                name="name"
                placeholder="State name"
                value={formData.name}
                onChange={handleChange}
            />

            {/*<TextArea*/}
            {/*    label="System Prompt"*/}
            {/*    type="text"*/}
            {/*    name="systemPrompt"*/}
            {/*    placeholder="Eg: you are helpful chatbot"*/}
            {/*    value={formData.systemPrompt}*/}
            {/*    onChange={handleChange}*/}
            {/*    rows={6}*/}
            {/*/>*/}

            <TextArea
                rootClass=""
                label="Prompt"
                type="text"
                name="inputPrompt"
                placeholder="recommend me a {dish} that's easy to cook?"
                value={formData.inputPrompt}
                onChange={handleChange}
                rows={6}
            />

            <DropDown
                className="pt-2"
                placeHolder="Select variable"
                label="Set result into variable"
                name="slotToAssign"
                value={formData.slotToAssign}
                options={Object.keys(metadata.slots)}
                onChange={handleChange}
            />

            <div className="flex flex-row justify-end">
                <button onClick={()=>{
                    handleChange({target: { name: 'slotToAssign', value: undefined }})
                }} className="underline text-xs">Reset Variable Selection</button>
            </div>

            <div className={getDividerCss(true, false)}>
                <UseChatHistorySwitch formData={formData} setFormData={setFormData} useChatHistory={formData.useChatHistory}/>

                {!formData.slotToAssign &&
                    <>
                        <AddToContextSwitch formData={formData} setFormData={setFormData} addToContext={formData.addToContext}/>

                        <SentToUserSwitch formData={formData} setFormData={setFormData} skipNotify={formData.skipNotify}/>
                    </>
                }
            </div>

        </div>
    )
}

export default AITaskSettings

import AITaskActionSettings from "./ai-task-action-settings";
import AuthSettings from "./auth-settings";
import AutoCaptureSlotsPromptSettings from "./auto-capture-slots-prompt-settings";
import ButtonsPromptSettings from "./buttons-prompt-settings";
import ChoiceSettings from "./choice/choice-settings";
import CodehookActionSettings from "./codehook-action-settings";
import CodehookPromptSettings from "./codehook-prompt-settings";
import DocSearchActionSettings from "./doc-search-action-settings";
import ImageMessageSettings from "./image-message-settings";
import JourneyActionSettings from "./journey-action-settings";
import IntegrationActionSettings from "./integration-settings";
import LiveAgentActionSettings from "./live-agent-action-settings";
import MessagePromptSettings from "./message-prompt-settings";
import PauseActionSettings from "./pause-action-settings";
import QuestionPromptSettings from "./question-prompt-settings";
import WebhookActionSettings from "./webhook-action-settings";
import WebhookPromptSettings from "./webhook-prompt-settings";

import {
    AI_TASK_ACTION_NODE_TYPE,
    AUTH_NODE_TYPE,
    AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE,
    BUTTONS_PROMPT_NODE_TYPE,
    CARDS_PROMPT_NODE_TYPE,
    CHOICE_NODE_TYPE,
    CODEHOOK_ACTION_NODE_TYPE,
    CODEHOOK_PROMPT_NODE_TYPE,
    DOC_SEARCH_ACTION_NODE_TYPE,
    IMAGE_PROMPT_NODE_TYPE,
    INTEGRATION_ACTION_NODE_TYPE,
    JOURNEY_ACTION_NODE_TYPE,
    LIVE_AGENT_ACTION_NODE_TYPE,
    MESSAGE_PROMPT_NODE_TYPE,
    PAUSE_ACTION_NODE_TYPE,
    QUESTION_PROMPT_NODE_TYPE,
    WEBHOOK_ACTION_NODE_TYPE,
    WEBHOOK_PROMPT_NODE_TYPE
} from "../common/const/node-types";
import CardsPromptSettings from "./cards-prompt-settings";

const settingsTypes = { };
settingsTypes[MESSAGE_PROMPT_NODE_TYPE] = { title: "Text Message", component: <MessagePromptSettings/>, icon: null};
settingsTypes[IMAGE_PROMPT_NODE_TYPE] = { title: "Image Message", component: <ImageMessageSettings/>, icon: null};
settingsTypes[QUESTION_PROMPT_NODE_TYPE] =  { title: "Question", component: <QuestionPromptSettings/>, icon: null};
settingsTypes[BUTTONS_PROMPT_NODE_TYPE] =  { title: "Buttons", component: <ButtonsPromptSettings/>, icon: null};
settingsTypes[CARDS_PROMPT_NODE_TYPE] =  { title: "Cards", component: <CardsPromptSettings/>, icon: null};
settingsTypes[WEBHOOK_PROMPT_NODE_TYPE] = { title: "Webhook", component: <WebhookPromptSettings/>, icon: null};
settingsTypes[CODEHOOK_PROMPT_NODE_TYPE] = { title: "Codehook", component: <CodehookPromptSettings/>, icon: null};
settingsTypes[AUTO_CAPTURE_SLOTS_PROMPT_NODE_TYPE] =  { title: "Auto Capture", component: <AutoCaptureSlotsPromptSettings/>, icon: null};
settingsTypes[CODEHOOK_ACTION_NODE_TYPE] = { title: "Codehook", component: <CodehookActionSettings/>, icon: null} ;
settingsTypes[WEBHOOK_ACTION_NODE_TYPE] = { title: "API", component: <WebhookActionSettings/>, icon: null};
settingsTypes[JOURNEY_ACTION_NODE_TYPE] = { title: "Journey", component: <JourneyActionSettings/>, icon: null};
settingsTypes[INTEGRATION_ACTION_NODE_TYPE] = { title: "Integration", component: <IntegrationActionSettings/>, icon: null};
settingsTypes[LIVE_AGENT_ACTION_NODE_TYPE] = { title: "HTM", component: <LiveAgentActionSettings/>, icon: null};
settingsTypes[DOC_SEARCH_ACTION_NODE_TYPE] = { title: "Doc Search", component: <DocSearchActionSettings/>, icon: null};
settingsTypes[AI_TASK_ACTION_NODE_TYPE] = { title: "AI Tasks", component: <AITaskActionSettings/>, icon: null};
settingsTypes[PAUSE_ACTION_NODE_TYPE] = { title: "Pause", component: <PauseActionSettings/>, icon: null};
settingsTypes[AUTH_NODE_TYPE] = { title: "Authenticate", component: <AuthSettings/>, icon: null};
settingsTypes[CHOICE_NODE_TYPE] = { title: "Condition", component: <ChoiceSettings/>, icon: null};

export {
    settingsTypes
};
